import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { render } from 'react-dom';
import { SignUpFooter } from './SignUpFooter';

const queryClient = new QueryClient();

render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<SignUpFooter />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('sign-up-footer')
);
