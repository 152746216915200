import { useMutation } from '@tanstack/react-query';
import classnames from 'classnames';
import { LoadingSpinner } from 'components/Common/LoadingSpinner';
import * as React from 'react';
import { handleNewsletterSignUp } from './api';
import './SignUpFooter.scss';

export function SignUpFooter() {
	const { mutateAsync: handleSignUp, status, data } = useMutation({
		mutationKey: ['sign-up-footer'],
		mutationFn: handleNewsletterSignUp,
	});
	const error = data?.errors?.email ?? data?.message ?? null;
	const isSubmitting = status === 'loading';
	const isSuccessful = status === 'success' && !error;

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		handleSignUp(formData);
	};

	let submitBtnText: React.ReactNode = 'Register';
	if (isSubmitting) {
		submitBtnText = <LoadingSpinner />;
	} else if (isSuccessful) {
		submitBtnText = 'Done';
	}

	return (
		<div className="SignUpFooter">
			<div className="SignUpFooter--Container o-wrapper u-px--small u-px--none--bp-large">
				<h4 className="SignUpFooter--Title">
					Sign up for exclusive deals and discounts
				</h4>
				<form
					className="SignUpFooter--Form"
					onSubmit={handleSubmit}
					noValidate
				>
					<input type="hidden" name="source" value="footer" />
					<input
						type="email"
						name="email"
						aria-label="email"
						placeholder="Enter your email address"
						className={classnames('SignUpFooter--Form--Input', {
							'SignUpFooter--Form--Input__success': isSuccessful,
						})}
					/>
					<div
						className={classnames('SignUpFooter--Form--Button', {
							'SignUpFooter--Form--Button__success': isSuccessful,
						})}
					>
						<button
							type="submit"
							disabled={isSubmitting}
							aria-label="submit"
						>
							{submitBtnText}
						</button>
					</div>
					<div className="c-input-message c-input-message--error">
						{error}
					</div>
				</form>
				<p className="SignUpFooter--Disclaimer">
					By entering your email you agree to Mobile Phones Direct
					sending marketing messages.
				</p>
			</div>
		</div>
	);
}
